import { ThunkAction } from 'redux-thunk';

import { Api } from '@services/api';
import { ESearchPredicateTypes } from '@common/definitions';
import type { Predicate } from '@common/types/objects';
import type { StoreState } from '@common/types/store';
import type { ListApiResponse } from '@common/types/util-types';
import * as ajax from './ajax';

import { EFilters } from './constants';
import { screenToPayload, transformScreenFromAPI } from '../survey/utils';
import * as draftReducer from '../learning/reducers/draft';
import type { Screen } from '../learning/types/objects';
import { EQuestionTypes } from '@modules/survey/definitions';
import type {
  APIForm,
  APIFormTemplate,
  APIFormSubmission,
  APIFormSubmissionDetail,
  APIFormTemplateDetail,
  FormCounts,
  SubmissionStatus,
} from './types';

type FetchFormsApiResponse = ListApiResponse<APIForm[], FormCounts>;

export type FetchFormsAction = {
  type: 'forms/RECEIVE_FORMS';
  items: APIForm[];
  pagination: {
    next_cursor: string | null;
  };
  meta: Record<string, unknown>;
  counts: FormCounts;
  strategy: 'clear' | 'append';
};

type ActualFetchFormsAction = ThunkAction<
  Promise<FetchFormsApiResponse>,
  StoreState,
  unknown,
  FetchFormsAction
>;

export type FetchFormsFilters = {
  status?: EFilters | null;
  sort_key?: 'created_at' | 'updated_at' | 'name';
  order?: 'asc' | 'desc';
  networkIds: string[];
  functionIds: string[];
  q?: string;
};

export const fetchForms = (
  nextCursor: string | null,
  filter: FetchFormsFilters,
  limit = 20,
  strategy?: boolean,
): ActualFetchFormsAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const sortBy = filter?.sort_key && `${filter.sort_key}_${filter.order}`;

  const query = Api.utils.toQuery({
    limit,
    cursor: nextCursor || true,
    status: filter.status,
    sort_by: sortBy,
    q: filter.q,
    network_ids: filter.networkIds,
    function_ids: filter.functionIds
  });

  const url = `/v1/organisations/${selected.id}/forms?${query}`;

  const request = await Api.get<FetchFormsApiResponse>(url);

  // @ts-expect-error
  dispatch({
    type: 'forms/RECEIVE_FORMS',
    items: request.data,
    pagination: request.meta.pagination,
    counts: request.meta.counts,
    strategy: strategy ? 'clear' : 'append',
  });

  return request;
};

type FetchFormApiResponse = ListApiResponse<APIForm>;

export type FetchFormAction = {
  type: 'forms/RECEIVE_FORM';
  item: APIForm;
  meta: any;
};

type ActualFetchFormAction = ThunkAction<
  Promise<FetchFormAction>,
  StoreState,
  unknown,
  FetchFormAction
>;

export function formatFormWithScreens(item: any) {
  return {
    ...item,
    screen_ids: item.screens.map((s: any) => s.id),
    screens: item.screens.map((screen: any, i: number) => ({
      ...screen,
      index: i,
      component_ids: screen.components.map((c: any) => c.id),
      question: screen.components.find(
        (component: any) => Object.values(EQuestionTypes).includes(component.type as any)
      ),
    }))
  };
}

export const fetchForm = (id: string): ActualFetchFormAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const formPath = `/v1/organisations/${selected.id}/forms/${id}`;
  const { data, meta } = await Api.get<FetchFormApiResponse>(formPath);

  return dispatch({
    type: 'forms/RECEIVE_FORM',
    item: formatFormWithScreens(data),
    meta
  });
};

export type FormPayload = {
  title: string;
  color: string;
  icon: string;
  status: 'published' | 'draft';
  response_statuses?: (Partial<SubmissionStatus> & { local?: boolean })[],
  audience: {
    predicate_type: ESearchPredicateTypes;
    predicates: Predicate[];
  },
  settings: {
    show_as_shortcut: boolean;
    show_status_to_users: boolean;
    notify_user_of_status_updates: boolean;
    is_anonymous?: boolean;
  };
};

export type CreateFormAction = {
  type: 'forms/CREATE_FORM';
  item: APIForm;
  meta?: any;
};

type ActualCreateFormAction = ThunkAction<
  Promise<CreateFormAction>,
  StoreState,
  unknown,
  CreateFormAction
>;

export const createForm = (
  payload: Partial<FormPayload> & { form_template_id?: string },
): ActualCreateFormAction => async (dispatch, getState) => {
  const { loggedUser, organisation: { selected } } = getState();
  const { data: item } = await Api.post<{ data: APIForm }>(`/v1/organisations/${selected.id}/forms`, payload);

  return dispatch({
    type: 'forms/CREATE_FORM',
    item,
    meta: {
      related: {
        users: [loggedUser.user],
      },
    },
  });
};

export type UpdateFormAction = {
  type: 'forms/UPDATE_FORM';
  item: APIForm;
};

type ActualUpdateFormAction = ThunkAction<
  Promise<UpdateFormAction>,
  StoreState,
  unknown,
  UpdateFormAction
>;

export const updateForm = (id: string, payload: Partial<FormPayload>): ActualUpdateFormAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: item } = await Api.put<{ data: APIForm }>(`/v1/organisations/${selected.id}/forms/${id}`, payload);

  return dispatch({
    type: 'forms/UPDATE_FORM',
    item,
  });
};

type FetchTemplatesResponse = ListApiResponse<APIFormTemplate[]>;

export type FetchTemplatesAction = {
  type: 'forms/RECEIVE_TEMPLATES';
  items: APIFormTemplate[];
};

type ActualFetchTemplatesAction = ThunkAction<
  Promise<FetchTemplatesResponse>,
  StoreState,
  unknown,
  FetchTemplatesAction
>;

export const fetchTemplates = (): ActualFetchTemplatesAction => async (dispatch, getState) => {
  const { loggedUser, organisation: { selected } } = getState();

  const query = Api.utils.toQuery({
    content_language_locale: loggedUser.user.language.locale === 'nl' ? 'nl' : 'en',
  });

  const result = await Api.get<FetchTemplatesResponse>(`/v1/organisations/${selected.id}/forms/templates?${query}`);

  dispatch({
    type: 'forms/RECEIVE_TEMPLATES',
    items: result.data,
  });

  return result;
};



export type FetchSubmissionsAction = {
  type: 'forms/RECEIVE_SUBMISSIONS';
  items: APIFormSubmission[];
  formId: string;
  pagination: {
    next_cursor: string | null;
  };
  counts: FormCounts;
  strategy: 'clear' | 'append';
};

type ActualFetchSubmissionsAction = ThunkAction<
  Promise<ajax.FetchSubmissionsApiResponse>,
  StoreState,
  unknown,
  FetchSubmissionsAction
>;



export const fetchSubmissions = (
  nextCursor: string | null,
  filter: ajax.FetchSubmissionsFilters,
  limit = 10,
  strategy?: boolean,
): ActualFetchSubmissionsAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();
  const request = await ajax.fetchSubmissions(selected.id, nextCursor, filter, limit);
  dispatch({
    type: 'forms/RECEIVE_SUBMISSIONS',
    formId: filter.formId,
    items: request.data,
    pagination: request.meta.pagination,
    counts: request.meta.counts,
    strategy: strategy ? 'clear' : 'append',
  });
  return request;
};

export type FetchSubmissionAction = {
  type: 'forms/RECEIVE_SUBMISSION';
  item: APIFormSubmissionDetail;
  meta: any;
};

type ActualFetchSubmissionAction = ThunkAction<
  Promise<FetchSubmissionAction>,
  StoreState,
  unknown,
  FetchSubmissionAction
>;

export const fetchSubmission = (
  formId: string,
  id: string
): ActualFetchSubmissionAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const response = await ajax.fetchSubmission(selected.id, formId, id);

  return dispatch({
    type: 'forms/RECEIVE_SUBMISSION',
    item: response.data,
    meta: response.meta
  });
};

export type UpdateSubmissionStatusAction = {
  type: 'forms/UPDATE_SUBMISSION_STATUS';
  submissionId: string;
  status: any;
};

type ActualUpdateSubmissionStatusAction = ThunkAction<
  Promise<UpdateSubmissionStatusAction>,
  StoreState,
  unknown,
  UpdateSubmissionStatusAction
>;

export const updateSubmissionStatus = (
  formId: string,
  submissionId: string,
  status: any,
): ActualUpdateSubmissionStatusAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();
  ajax.updateSubmissionStatus(
    selected.id, formId, submissionId, status
  ).then((response: any) => {
    dispatch({
      // @ts-expect-error
      type: 'forms/RECEIVE_SUBMISSION',
      item: response.data,
      meta: response.meta
    });
  });

  return dispatch({
    type: 'forms/UPDATE_SUBMISSION_STATUS',
    submissionId,
    status,
  });
};

export type CreateQuestionAction = {
  type: 'forms/CREATE_QUESTION';
  screen: Screen;
  formId: string;
};

export type ActualCreateQuestionAction = ThunkAction<
  Promise<CreateQuestionAction>,
  StoreState,
  unknown,
  CreateQuestionAction
>;

export const createQuestion = (
  formId: string,
  payload: Screen | Record<never, never>,
): ActualCreateQuestionAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data } = await Api.post<{ data: Screen }>(`/v1/organisations/${selected.id}/forms/${formId}/screens`, payload);

  const screen = transformScreenFromAPI(data);

  return dispatch({
    type: 'forms/CREATE_QUESTION',
    formId,
    screen,
  });
};

export type DeleteQuestionAction = {
  type: 'forms/DELETE_QUESTION';
  id: string;
  formId: string;
};

export type ActualDeleteQuestionAction = ThunkAction<
  Promise<DeleteQuestionAction>,
  StoreState,
  unknown,
  DeleteQuestionAction
>;

export const deleteQuestion = (formId: string, screenId: string): ActualDeleteQuestionAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.delete(`/v1/organisations/${selected.id}/forms/${formId}/screens/${screenId}`);

  return dispatch({
    type: 'forms/DELETE_QUESTION',
    id: screenId,
    formId,
  });
};

export type UpdateScreenOrderAction = {
  type: 'forms/UPDATE_SCREEN_ORDER';
  formId: string;
  items: { id: string, index: number }[];
};

export const updateScreenOrder = (formId: string, order: string[]): UpdateScreenOrderAction => ({
  type: 'forms/UPDATE_SCREEN_ORDER',
  formId,
  items: order.map((id, index) => ({ id, index })),
});

export type SaveScreenOrderAction = {
  type: 'forms/SAVE_SCREEN_ORDER';
  formId: string;
  order: string[];
};

export type ActualSaveScreenOrderAction = ThunkAction<
  Promise<SaveScreenOrderAction>,
  StoreState,
  unknown,
  SaveScreenOrderAction
>;

export const saveScreenOrder = (formId: string, order: string[]): ActualSaveScreenOrderAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.put(`/v1/organisations/${selected.id}/forms/${formId}/screens`, { screens: order });

  return dispatch({
    type: 'forms/SAVE_SCREEN_ORDER',
    order,
    formId,
  });
};

export type DuplicateScreenAction = {
  type: 'forms/DUPLICATE_SCREEN';
  duplicatedFromScreenId: string;
  screen: Screen;
  formId: string;
};

export type ActualDuplicateScreenAction = ThunkAction<
  Promise<DuplicateScreenAction>,
  StoreState,
  unknown,
  DuplicateScreenAction
>;

export const duplicateScreen = (formId: string, screenId: string): ActualDuplicateScreenAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const {
    data,
  } = await Api.post<{ data: Screen }>(`/v1/organisations/${selected.id}/forms/${formId}/screens/${screenId}/duplicate`);

  const screen = transformScreenFromAPI(data);

  return dispatch({
    type: 'forms/DUPLICATE_SCREEN',
    duplicatedFromScreenId: screenId,
    screen,
    formId,
  });
};

type SaveDraftAction = {
  type: 'forms/SAVE_DRAFT';
  screen: Screen;
  formId: string;
};

type ActualSaveDraftAction = ThunkAction<
  Promise<SaveDraftAction[]>,
  StoreState,
  unknown,
  SaveDraftAction
>;

export const saveDraft = (formId: string): ActualSaveDraftAction => async (dispatch, getState) => {
  const state = getState();
  const { organisation: { selected }, academy: { draft }, forms } = state;

  const form = forms.items[formId];
  const screenIds = Object.keys(draft.screens);

  return Promise.all(screenIds.map(async (id) => {
    const screen = draftReducer.getScreen(state, id);
    // @ts-ignore
    const payload = screenToPayload(screen, form);

    const result = await Api.put(
      `/v1/organisations/${selected.id}/forms/${formId}/screens/${id}`,
      payload
    );

    return dispatch({
      type: 'forms/SAVE_DRAFT',
      // @ts-ignore
      screen: transformScreenFromAPI(result.data),
      formId,
    });
  }));
};

export type ArchiveFormAction = {
  type: 'forms/ARCHIVE_FORM';
  previousStatus: APIForm['status'];
  formId: string;
};

export type ActualArchiveFormAction = ThunkAction<
  Promise<ArchiveFormAction>,
  StoreState,
  unknown,
  ArchiveFormAction
>;

export const archiveForm = (
  formId: string,
  previousStatus: APIForm['status'],
): ActualArchiveFormAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.post(`/v1/organisations/${selected.id}/forms/${formId}/archive`);

  return dispatch({
    type: 'forms/ARCHIVE_FORM',
    previousStatus,
    formId,
  });
};

export type UnarchiveFormAction = {
  type: 'forms/UNARCHIVE_FORM';
  formId: string;
};
export type ActualUnarchiveFormAction = ThunkAction<
  Promise<UnarchiveFormAction>,
  StoreState,
  unknown,
  UnarchiveFormAction
>;
export const unarchiveForm = (
  formId: string,
): ActualUnarchiveFormAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.post(`/v1/organisations/${selected.id}/forms/${formId}/unarchive`);

  return dispatch({
    type: 'forms/UNARCHIVE_FORM',
    formId
  });
};

export type SaveAsTemplateAction = {
  type: 'forms/SAVE_AS_TEMPLATE';
  item: APIFormTemplate;
};

export type ActualSaveAsTemplateAction = ThunkAction<
  Promise<SaveAsTemplateAction>,
  StoreState,
  unknown,
  SaveAsTemplateAction
>;

export const saveAsTemplate = (
  id: string,
  payload: { title: string },
): ActualSaveAsTemplateAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: item } = await Api.post<{ data: APIFormTemplate }>(`/v1/organisations/${selected.id}/forms/${id}/templates`, {
    title: payload.title,
  });

  return dispatch({
    type: 'forms/SAVE_AS_TEMPLATE',
    item,
  });
};

export type FetchTemplateDetailAction = {
  type: 'forms/RECEIVE_TEMPLATE_DETAIL';
  item: APIFormTemplateDetail;
};

export type ActualFetchTemplateDetailAction = ThunkAction<
  Promise<FetchTemplateDetailAction>,
  StoreState,
  unknown,
  FetchTemplateDetailAction
>;

export const fetchTemplateDetail = (id: string): ActualFetchTemplateDetailAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: item } = await Api.get<{ data: APIFormTemplateDetail }>(`/v1/organisations/${selected.id}/forms/templates/${id}`);

  return dispatch({
    type: 'forms/RECEIVE_TEMPLATE_DETAIL',
    item,
  });
};

export type DuplicateFormAction = {
  type: 'forms/DUPLICATE_FORM';
  item: APIForm;
};

export type ActualDuplicateFormAction = ThunkAction<
  Promise<DuplicateFormAction>,
  StoreState,
  unknown,
  DuplicateFormAction
>;

export const duplicateForm = (id: string): ActualDuplicateFormAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: item } = await Api.post<{ data: APIForm }>(`/v1/organisations/${selected.id}/forms/${id}/duplicate`);

  return dispatch({
    type: 'forms/DUPLICATE_FORM',
    item,
  });
};

type ActualDownloadSubmissionsAction = ThunkAction<
  Promise<void>,
  StoreState,
  unknown,
  any
>;

export const downloadSubmissions = (
  formId: string,
  fileName: string,
  extension: 'csv' | 'xlsx'
): ActualDownloadSubmissionsAction => (_, getState) => {
  const { organisation: { selected } } = getState();
  return ajax.downloadSubmissions(selected.id, formId, fileName, extension);
};

export type DeleteFormAction = {
  type: 'forms/DELETE_FORM';
  formId: string;
  filter: APIForm['status'];
};

export type ActualDeleteFormAction = ThunkAction<
  Promise<DeleteFormAction>,
  StoreState,
  unknown,
  DeleteFormAction
>;

export const deleteForm = (formId: string, filter: APIForm['status']): ActualDeleteFormAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.delete(`/v1/organisations/${selected.id}/forms/${formId}`);

  return dispatch({
    type: 'forms/DELETE_FORM',
    formId,
    filter,
  });
};

export type DeleteSubmissionAction = {
  type: 'forms/DELETE_SUBMISSION';
  formId: string;
  submissionId: string;
};

export type UpdateModeratorsAction = {
  type: 'forms/UPDATE_MODERATORS',
  response: any
};

export type SetUserFormNotificationSettingsAction = {
  type: 'forms/SET_USER_FORM_NOTIFICATION_SETTINGS',
  formId: string,
  userId: string,
  enabled: boolean
};

export type ActualDeleteSubmissionAction = ThunkAction<
  Promise<DeleteSubmissionAction>,
  StoreState,
  unknown,
  DeleteSubmissionAction
>;

export const deleteSubmission = (
  formId: string,
  submissionId: string,
): ActualDeleteSubmissionAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await ajax.deleteSubmission(selected.id, formId, submissionId);

  return dispatch({
    type: 'forms/DELETE_SUBMISSION',
    formId,
    submissionId,
  });
};
